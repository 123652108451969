import { useState, useEffect } from 'react';
import { trans, getPagePath } from '@spotahome/soyuz/client';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SwipeableViews from 'react-swipeable-views';

import { Button } from '@spotahome/ui-library';
import Tracking from '@spotahome/soyuz-tracking';

import { useInView } from 'react-intersection-observer';

import PropTypes from 'prop-types';

import { trackLandlordLandingSectionViewed } from '../../utils/track';

import UniqueSellingPoint from './UniqueSellingPoint';
import ProfitIcon from './icons/ProfitIcon';
import MoneyBoxIcon from './icons/MoneyBoxIcon';
import KeyIcon from './icons/KeyIcon';

import {
  StyledSection,
  StyledRadioGroup,
  StyledRadio,
  StyledContainer,
  StyledSectionTitle,
  StyledElements,
  StyledCta
} from './styles';

const sellingPoints = [
  {
    renderIcon: () => <ProfitIcon />,
    title: trans(
      'landing.landlord.section.uniqueSellingPoint.profitability.title'
    ),
    description: trans(
      'landing.landlord.section.uniqueSellingPoint.profitability.text'
    )
  },
  {
    renderIcon: () => <MoneyBoxIcon />,
    title: trans('landing.landlord.section.uniqueSellingPoint.security.title'),
    description: trans(
      'landing.landlord.section.uniqueSellingPoint.security.text'
    )
  },
  {
    renderIcon: () => <KeyIcon />,
    title: trans(
      'landing.landlord.section.uniqueSellingPoint.hassle-free.title'
    ),
    description: trans(
      'landing.landlord.section.uniqueSellingPoint.hassle-free.text'
    )
  }
];

const track = label => {
  Tracking.ga.sendEvent(
    'landlord_landing',
    'feature_unique_selling_point',
    label
  );
  Tracking.segment.trackEvent(
    'landlord_landing_feature_unique_selling_point',
    null,
    {
      label
    }
  );
};

const renderUspElements = () => {
  return sellingPoints.map(usp => (
    <UniqueSellingPoint
      key={usp.title}
      renderIcon={usp.renderIcon}
      title={trans(usp.title)}
      description={trans(usp.description)}
    />
  ));
};

const MobileWrappedUspElements = ({
  index,
  handleChangeIndex,
  handleChangeRadio
}) => {
  return (
    <>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        {renderUspElements()}
      </SwipeableViews>
      <StyledRadioGroup
        defaultValue={index}
        name="swiper"
        value={index}
        onChange={handleChangeRadio}
        row
      >
        {sellingPoints.map((sellingPoint, i) => (
          <StyledRadio
            key={sellingPoint.title}
            variant="outlined"
            value={i}
            size="sm"
            color="primary"
          />
        ))}
      </StyledRadioGroup>
    </>
  );
};

MobileWrappedUspElements.propTypes = {
  index: PropTypes.number.isRequired,
  handleChangeIndex: PropTypes.func.isRequired,
  handleChangeRadio: PropTypes.func.isRequired
};

const UniqueSellingPointSection = () => {
  const muiTheme = useTheme();
  const isBiggerThanMobile = useMediaQuery(muiTheme.breakpoints.up('md'));
  const [index, setIndex] = useState(0);

  const handleChangeIndex = newIndex => {
    setIndex(newIndex);
  };
  const handleChangeRadio = (e, value) => {
    setIndex(Number(value));
  };

  const [uniqueSellingPointRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      track('view');
      trackLandlordLandingSectionViewed('unique-selling-points');
    }
  }, [inView]);

  const trackSignUp = () => track('sign_up');

  return (
    <StyledSection ref={uniqueSellingPointRef}>
      <StyledContainer>
        <StyledSectionTitle>
          {trans('landing.landlord.section.uniqueSellingPoint.title')}
        </StyledSectionTitle>
        <StyledElements>
          {isBiggerThanMobile ? (
            renderUspElements()
          ) : (
            <MobileWrappedUspElements
              index={index}
              handleChangeIndex={handleChangeIndex}
              handleChangeRadio={handleChangeRadio}
            />
          )}
        </StyledElements>
        <StyledCta>
          <Button
            color="primary"
            href={getPagePath('landlord.signup')}
            onClick={trackSignUp}
          >
            {trans('landlord.landing.standard.footer.cta')}
          </Button>
        </StyledCta>
      </StyledContainer>
    </StyledSection>
  );
};

export default UniqueSellingPointSection;
