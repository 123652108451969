import PropTypes from 'prop-types';

import { StyledImage } from './FooterLazyImage.style';

const FooterLazyImage = props => (
  <StyledImage src={props.src} alt={props.alt} loading="lazy" />
);

FooterLazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default FooterLazyImage;
